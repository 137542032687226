'use strict'

const service = config.VUE_APP_service_licenze || ''

async function leggeFileImpostazione(pathId) {
  try {
    const response = await fetch(`${service}/configs/singola?pathId=${pathId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (error) {
    // errorService.onError(error, 'getRivenditori')
    return []
  }
}

async function leggeAlberoImpostazioni() {
  try {
    const response = await fetch(`${service}/configs`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (error) {
    // errorService.onError(error, 'getRivenditori')
    return []
  }
}

async function aggiornaPrerequisiti(codiceCliente, prerequisiti) {
  try {
    const data = {
      codiceCliente,
      prerequisiti
    }
    const response = await fetch(`${service}/configs/prerequisiti`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

export default {
  leggeFileImpostazione,
  leggeAlberoImpostazioni,
  aggiornaPrerequisiti
}
