'use strict'

const service = config.VUE_APP_service_licenze || ''

async function getSniplets() {
  try {
    const response = await fetch(`${service}/sniplets`, {
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return []
  }
}

async function getModello() {
  const response = await fetch(`${service}/sniplets/modello`)
  return await response.json()
}

async function salvaGruppo(gruppo) {
  try {
    const data = {
      gruppo
    }
    const response = await fetch(`${service}/sniplets/gruppo/${gruppo.codice}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function cancellaGruppo(gruppo) {
  try {
    const response = await fetch(`${service}/sniplets/delete/gruppo/${gruppo.codice}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function salvaConfigurazione(sniplet) {
  try {
    const data = {
      sniplet
    }
    const response = await fetch(`${service}/sniplets/${sniplet.codice}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function cancellaConfigurazione(sniplet) {
  try {
    const response = await fetch(`${service}/sniplets/delete/${sniplet.codice}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function salvaConfigurazioneCliente(clienteCodice, sniplets) {
  try {
    const data = {
      sniplets
    }
    const response = await fetch(`${service}/sniplets/cliente/${clienteCodice}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

/* async function creaCliente(cliente) {
  try {
    const response = await fetch(`${service}/clienti/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cliente)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
} */

/* async function modificaCliente(cliente) {
  try {
    const response = await fetch(`${service}/clienti/${cliente._id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cliente)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}
 */


export default {
  getSniplets,
  getModello,
  salvaGruppo,
  cancellaGruppo,
  salvaConfigurazione,
  cancellaConfigurazione,
  salvaConfigurazioneCliente
/*   creaCliente,
  modificaCliente */
}
