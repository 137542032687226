'use strict'
import store from '../store'

const service = config.VUE_APP_service_licenze || ''

function getToken() {
  return store.getters['utenti/user'].token || ''
}

async function getClienti() {
  try {
    const response = await fetch(`${service}/clienti`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken()
      }
    })
    return await response.json()
  } catch (error) {
    // errorService.onError(error, 'getClienti')
    return []
  }
}

async function getModello() {
  const response = await fetch(`${service}/clienti/modello`)
  return await response.json()
}

async function creaCliente(cliente) {
  try {
    const response = await fetch(`${service}/clienti/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cliente)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function modificaCliente(cliente) {
  try {
    const response = await fetch(`${service}/clienti/${cliente.codice}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cliente)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}



export default {
  getClienti,
  getModello,
  creaCliente,
  modificaCliente
}
