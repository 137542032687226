'use strict'
// var http = require('http');
// var fs = require('fs');
/* const got = require("got");
const { createWriteStream } = require("fs");
const stream = require("stream");
const { promisify } = require("util");
const pipeline = promisify(stream.pipeline); */


// Per restore usare multer + multiple files
// https://stackabuse.com/handling-file-uploads-in-node-js-with-expres-and-multer/
// Vedi esempio su routes/migrazione.js

const service = config.VUE_APP_service_licenze || ''

async function backupDatabase(dbname, filename, cb) {
  try {
    const response = await fetch(`${service}/utility/backup/${dbname}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ filename })
    })
    return await response.json()
  } catch (error) {
    console.log('error backupDatabase', error)
    return ''
  }
}

function getBackupLink(filename) {
  return `${service}/utility/download/backup?fn=${filename}`
}

async function uploadFileForm(form_data) {
  try {
    const response = await fetch(`${service}/utility/upload/single`, {
      method: 'POST',
      body: form_data
    })
    await response.text()
  } catch (err) {
    return err
  }
}

async function restoreDatabase(dbname, filename) {
  try {
    const response = await fetch(`${service}/utility/restore/${dbname}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ filename })
    })
    const result = await response.json()
  } catch (error) {
    console.log('error restoreDatabase', error)
    return ''
  }
}

async function uploadRepositoryForm(form_data) {
  try {
    const response = await fetch(`${service}/utility/upload/repository`, {
      method: 'POST',
      body: form_data
    })
    return await response.text()
  } catch (err) {
    return err
  }
}

async function uploadRepositoryMultipleFile(form_data) {
  try {
    const response = await fetch(`${service}/utility/upload/repository/multiple`, {
      method: 'POST',
      body: form_data
    })
    return await response.text()
  } catch (err) {
    return err
  }
}

async function getk8FileList() {
  const response = await fetch(`${service}/utility/repository`)
  return await response.json()
}

async function getk8FolderFileList(folder) {
  const response = await fetch(`${service}/utility/repository/folder?dir=${folder}`)
  return await response.json()
}

async function deleteFile(file) {
  const data = {
    file
  }
  const response = await fetch(`${service}/utility/repository/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function createFolder(folder) {
  const data = {
    folder
  }
  const response = await fetch(`${service}/utility/repository/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function deleteFolder(folder) {
  const data = {
    folder
  }
  const response = await fetch(`${service}/utility/repository/folder/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function saveFile(folder, files) {
  const data = {
    folder,
    files
  }
  const response = await fetch(`${service}/utility/repository/configfiles`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function getModuleVersion() {
  const response = await fetch(`${service}/utility/info/moduli`)
  return await response.json()
}

async function getCustomeModuleVersion(baseDb) {
  const response = await fetch(`${service}/utility/info/cliente/${baseDb}`)
  return await response.json()
}

async function statoElaborazione(tipo) {
  try {
    // In base al tipo posso cambiare l'endpoint da chiamare. Per adesso solo questo ...
    const response = await fetch(`${service}/utility/elaborazione/stato`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return err
  }
}

async function compactDatabase(dbname) {
  try {
    const response = await fetch(`${service}/utility/compact/${dbname}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (error) {
    console.log('error compactDatabase', error)
    return ''
  }
}

export default {
  backupDatabase,
  getBackupLink,
  restoreDatabase,
  uploadFileForm,
  uploadRepositoryForm,
  uploadRepositoryMultipleFile,
  getk8FileList,
  getk8FolderFileList,
  deleteFile,
  saveFile,
  createFolder,
  deleteFolder,
  getModuleVersion,
  getCustomeModuleVersion,
  statoElaborazione,
  compactDatabase
}
