'use strict'

const service = config.VUE_APP_service_licenze || ''

async function getElencoConfigurazioni(dbName, includeAllDocs) {
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}?keys=${includeAllDocs}`)
  const result = await response.json()
  return result.rows
}

async function modificaConfigurazione(dbName, configurazione, istanzaDb, cancellare) {
  const data = { ...configurazione, cancellare }
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/update${istanzaDb ? '?istanza=' +  istanzaDb : ''}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function modificaConfigurazioneSub(dbName, configurazioneId, subConf, istanzaDb, cancellare) {
  const data = {
    configurazioneId,
    subConf,
    cancellare
  } 
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/sub/update${istanzaDb ? '?istanza=' +  istanzaDb : ''}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function modificaConfigurazioneSubDettaglio(dbName, configurazioneId, subConf, payload, istanzaDb, cancellare) {
  const data = {
    configurazioneId,
    subConf,
    payload,
    cancellare
  } 
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/sub/dettaglio/update${istanzaDb ? '?istanza=' +  istanzaDb : ''}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function leggeConfigurazione(dbName, payload, istanzaDb) {
  const confId = payload._id
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/${confId}${istanzaDb ? '?istanza=' +  istanzaDb : ''}`)
  return await response.json()
}

async function leggeConfigurazioneSub(dbName, confId, payload, istanzaDb) {
  let subArr = ''
  if (typeof payload === 'string') {
    subArr = payload
  } else {
    subArr = Object.getOwnPropertyNames(payload).filter(x => !x.startsWith('__'))
  }
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/${confId}?arr=${subArr}${istanzaDb ? '&istanza=' +  istanzaDb : ''}`)
  return await response.json()
}

async function leggeConfigurazioneSubDettaglio(dbName, confId, subConf, payload, istanzaDb) {
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/${confId}/dettaglio?subConf=${subConf}${istanzaDb ? '&istanza=' +  istanzaDb : ''}`)
  return await response.json()  
}

async function getDatiGenerici(dbName, query, fields, limit, istanzaDb) {
  var data = {
    query,
    fields,
    limit,
    istanzaDb
  }
  const response = await fetch(`${service}/backoffice/generico/${dbName}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function modificaItemGenerico(dbName, item) {
  const response = await fetch(`${service}/backoffice/generico/item/${dbName}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(item)
  })
  return await response.json()
}

async function getItemGenerico(dbName, payload) {
  try {
    const itemId = payload._id
    const response = await fetch(`${service}/backoffice/generico/item/${dbName}/${itemId}`)
    return await response.json()
  } catch {
    return null
  }
}

export default {
  getElencoConfigurazioni,
  modificaConfigurazione,
  modificaConfigurazioneSub,
  modificaConfigurazioneSubDettaglio,
  leggeConfigurazione,
  leggeConfigurazioneSub,
  leggeConfigurazioneSubDettaglio,
  getDatiGenerici,
  modificaItemGenerico,
  getItemGenerico
}
